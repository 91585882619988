<template>
  <div>
    <div class="search-bar">
      <a-form-model :labelCol="{ span: 8 }"
                    :wrapperCol="{ span: 16 }"
                    ref="searchRef"
                    :model="searchForm">
        <a-row>
          <!-- 第一排 -->
          <a-col :span="6">
            <a-form-model-item prop="outlets_city_adcode" label="订单城市">
              <a-select style="width: 100%" placeholder="选择订单城市"
                        v-model="searchOutletsCityAdcode"
                        @change="onFormChange">
                <a-select-option v-if="!isCityManage" key="" value="">不限</a-select-option>
                <a-select-option v-for="(item, index) in manageCityList"
                                 :key="index"
                                 :value="Number(item.adcode)">{{ item.name }}</a-select-option>
              </a-select>
            </a-form-model-item>
          </a-col>
          <a-col :span="6">
            <a-form-model-item prop="order_no"
                               label="订单ID">
              <a-input allowClear
                       v-model="searchForm.order_no"
                       enter-button
                       placeholder="请输入订单ID"></a-input>
            </a-form-model-item>
          </a-col>
          <a-col :span="6">
            <a-form-model-item prop="order_source"
                               label="订单来源">
              <div class="flex items-center" style="height:40px;">
                <a-select placeholder="请选择订单来源" style="flex:1;"
                          v-model="searchForm.order_source" @change="onChangeOrderSource">
                  <a-select-option v-for="(item, index) in orderSourceOptions"
                                   :key="index"
                                   :value="item.id">{{item.name}}</a-select-option>
                </a-select>
                <a-select v-if="searchForm.order_source==10" placeholder="是否第三方挂账"
                          style="flex:1;"
                          v-model="searchForm.pay_subtype">
                  <a-select-option key="" value="">不限</a-select-option>
                  <a-select-option key="-1" value="-1">非第三方</a-select-option>
                  <a-select-option
                          v-for="item in subOnAccountPayTypeList"
                          :key="item.id" :value="item.id">{{ item.name }}</a-select-option>
                </a-select>
              </div>
            </a-form-model-item>
          </a-col>
          <a-col :span="6">
            <a-form-model-item prop="searchConsignee"
                               label="收/提货人">
              <a-input allowClear
                       v-model="searchConsignee"
                       placeholder="姓名 / 手机号"></a-input>
            </a-form-model-item>
          </a-col>
          <!-- <a-col :span="6">
            <a-form-model-item prop="consignee_recipient_phone"
                               label="收/提货手机号">
              <a-input allowClear
                       enter-button
                       v-model="searchForm.consignee_recipient_phone"
                       placeholder="请输入手机号"></a-input>
            </a-form-model-item>
          </a-col> -->
        </a-row>
        <!-- 第二排 -->
        <a-row>
          <a-col :span="6">
            <a-form-model-item prop="goods_name"
                               label="商品名称">
              <!-- <a-input v-model="searchForm.goods_name"
                       enter-button
                       allowClear
                       placeholder="请输入商品名称"></a-input> -->
              <a-select placeholder="输入商品名"
                      v-model="searchForm.goods_id"
                      show-search allowClear
                      :default-active-first-option="false"
                      :show-arrow="false"
                      :filter-option="false"
                      :not-found-content="null"
                      @search="handlerSuggestGoods">
                <a-select-option v-for="item in suggestGoodsList"
                         :key="item.goods_id"
                         :value="item.goods_id">{{ item.goods_name }}</a-select-option>
              </a-select>
            </a-form-model-item>
          </a-col>
          <a-col :span="6">
            <a-form-model-item prop="order_amount_start"
                               label="订单金额"
                               class="flex">
              <div class="flex items-center">
                <a-input-number id="inputNumber"
                                v-model="searchForm.order_amount_start"
                                :min="0" />~
                <a-input-number id="inputNumber2"
                                v-model="searchForm.order_amount_end"
                                :min="0" />
              </div>
            </a-form-model-item>
          </a-col>
          <a-col :span="6">
            <a-form-model-item prop="order_add_time_arr"
                               label="下单日期">
              <a-range-picker v-model="searchForm.order_add_time_arr"
                              valueFormat="YYYY-MM-DD" />
            </a-form-model-item>
          </a-col>
          <a-col :span="6">
            <a-form-model-item prop="pay_flow_no"
                               label="支付流水号">
              <a-input allowClear
                       enter-button
                       v-model="searchForm.pay_flow_no"
                       placeholder="请输入支付流水号"></a-input>
            </a-form-model-item>
          </a-col>
        </a-row>

        <!-- 第三排 -->
        <a-row v-if="expand">
          <a-col :span="6">
            <a-form-model-item prop="order_status"
                               label="订单状态">
              <a-select placeholder="请选择订单状态"
                        v-model="searchForm.order_status"
                        @change="onFormChange">
                <a-select-option v-for="(item, index) in orderStatusOptions"
                                 :key="index"
                                 :value="item.id">{{item.name}}</a-select-option>
              </a-select>
            </a-form-model-item>
          </a-col>
          <a-col :span="6">
            <a-form-model-item prop="orderer_phone"
                               label="订货人电话">
              <a-input allowClear
                       v-model="searchForm.orderer_phone"
                       enter-button
                       placeholder="下单人/联系人电话"></a-input>
            </a-form-model-item>
          </a-col>

          <a-col :span="6">
            <a-form-model-item prop="consignee_address"
                               label="收货地址">
              <a-input allowClear
                       enter-button
                       v-model="searchForm.consignee_address"
                       placeholder="请输入收货地址"></a-input>
            </a-form-model-item>
          </a-col>
          <a-col :span="6">
            <a-form-model-item prop="consignee_recipient_time_arr"
                               label="收/提货日期">
              <a-range-picker valueFormat="YYYY-MM-DD"
                              v-model="searchForm.consignee_recipient_time_arr" />
            </a-form-model-item>
          </a-col>
        </a-row>

        <!-- 第四排 -->
        <a-row v-if="expand">
          <a-col :span="6">
            <a-form-model-item prop="actual_sale_ascription_outlets_id"
                               label="负责网点">
              <a-select show-search allowClear
                        placeholder="请输入负责网点"
                        :default-active-first-option="false"
                        :show-arrow="false"
                        :filter-option="false"
                        :not-found-content="null"
                        @search="initOutletsList"
                        v-model="searchForm.actual_sale_ascription_outlets_id">
                <a-select-option v-for="(item, index) in outletsList"
                                 :key="index"
                                 :value="item.outlets_id">{{item.outlets_name}}</a-select-option>
              </a-select>
            </a-form-model-item>
          </a-col>

          <a-col :span="6">
            <a-form-model-item prop="order_type"
                               label="配送方式">
              <a-select placeholder="请选择配送方式"
                        v-model="searchForm.order_type">
                <a-select-option v-for="(item, index) in orderTypeOptions"
                                 :key="index"
                                 :value="item.id">{{item.name}}</a-select-option>
              </a-select>
            </a-form-model-item>
          </a-col>

          <a-col :span="6">
            <a-form-model-item prop="goods_type"
                               label="商品类型">
              <a-select v-model="searchForm.goods_type"
                        placeholder="产品属性">
                <a-select-option :value="0">全部</a-select-option>
                <a-select-option v-for="item in Object.keys(goodsType)"
                                 :value="Number(item)"
                                 :key="item">{{goodsType[item]}}</a-select-option>
              </a-select>
            </a-form-model-item>
          </a-col>
          <a-col :span="6">
            <a-form-model-item prop="acceptance_time_arr"
                               label="受理时间">
              <a-range-picker valueFormat="YYYY-MM-DD"
                              v-model="searchForm.acceptance_time_arr" />
            </a-form-model-item>
          </a-col>
        </a-row>

        <!-- 第五排 -->
        <a-row v-if="expand">
          <a-col :span="6">
            <a-form-model-item prop="order_remarks"
                               label="下单备注">
              <a-input allowClear
                       v-model="searchForm.order_remarks"
                       enter-button
                       placeholder="请输入备注"></a-input>
            </a-form-model-item>
          </a-col>
          <a-col :span="6">
            <a-form-model-item prop="pay_mode"
                               label="支付方式">
              <a-select placeholder="请选择支付方式"
                        v-model="searchForm.pay_mode">
                <a-select-option v-for="(item, index) in orderModalOptions"
                                 :key="index"
                                 :value="item.id">{{item.name}}</a-select-option>
              </a-select>
            </a-form-model-item>
          </a-col>

          <a-col :span="6">
            <a-form-model-item prop="order_finish_time_arr"
                               label="完成时间">
              <a-range-picker valueFormat="YYYY-MM-DD"
                              v-model="searchForm.order_finish_time_arr" />
            </a-form-model-item>
          </a-col>
          <a-col :span="6">
            <a-form-model-item prop="order_cancel_time_arr"
                               label="取消时间">
              <a-range-picker valueFormat="YYYY-MM-DD"
                              v-model="searchForm.order_cancel_time_arr" />
            </a-form-model-item>
          </a-col>
        </a-row>

        <!-- 第六排 -->
        <a-row>
          <a-col :span="6">
            <a-form-model-item prop="create_staff_id"
                               label="创建人">
              <a-select show-search allowClear
                        placeholder="请输入创建人"
                        :default-active-first-option="false"
                        :show-arrow="false"
                        :filter-option="false"
                        :not-found-content="null"
                        @search="getStaffList"
                        v-model="searchForm.create_staff_id">
                <a-select-option v-for="(item, index) in staffList"
                                 :key="index"
                                 :value="item.staff_id">{{item.staff_name}}</a-select-option>
              </a-select>
            </a-form-model-item>
          </a-col>
          <a-col :span="6">
            <a-form-model-item prop="dispatch_staff_id"
                               label="调度人">
              <a-select show-search allowClear
                        placeholder="请输入调度人"
                        :default-active-first-option="false"
                        :show-arrow="false"
                        :filter-option="false"
                        :not-found-content="null"
                        @search="getStaffList"
                        v-model="searchForm.dispatch_staff_id">
                <a-select-option v-for="(item, index) in staffList"
                                 :key="index"
                                 :value="item.staff_id">{{item.staff_name}}</a-select-option>
              </a-select>
            </a-form-model-item>
          </a-col>

          <!-- <a-col :span="6">
            <a-form-model-item prop="is_update"
                               label="订单修改">
              <a-checkbox-group v-model="searchForm.is_update"
                                :options="orderIsModifyList"
                                style="width:270px" />
            </a-form-model-item>
          </a-col>
          <a-col :span="6">
            <div class="flex items-center justify-end mr-1 mt-1">
              <a style="font-size:12px;"
                 @click="handlerExpand">
                {{!expand?'展开':'收起'}}
                <a-icon :type="expand ? 'up' : 'down'" />
              </a>
              <a-button class="ml-4"
                        @click="resetForm">重置</a-button>
              <a-button class="ml-2" type="primary"
                        html-type="submit"
                        @click="handlerSearch">搜索</a-button>
            </div>
          </a-col> -->

          <a-col :span="12">
            <a-form-model-item :labelCol="{ span: 4 }" :wrapperCol="{ span: 20 }"
                               prop="is_update" label="订单修改">
              <div class="flex justify-between items-center">
                <a-checkbox-group v-model="searchForm.is_update"
                                  :options="orderIsModifyList" />
                <div>
                  <a style="font-size:12px;"
                     @click="handlerExpand">
                    {{!expand?'展开':'收起'}}
                    <a-icon :type="expand ? 'up' : 'down'" />
                  </a>
                  <a-button class="ml-4 p-0" type="link" @click="resetForm">重置</a-button>
                  <a-button class="ml-2" type="primary" :loading="isLoading" @click="handlerSearch">搜索</a-button>
                </div>
              </div>
            </a-form-model-item>
          </a-col>
        </a-row>
      </a-form-model>
    </div>

    <div class="mt-3 flex justify-between items-center">
      <div class="flex items-center">
        <a-tabs type="card" :activeKey="activeKey"
                @change="handlerTypeChange">
          <a-tab-pane v-for="item in typeList"
                      :key="item.type_id"
                      :tab="item.type_name"></a-tab-pane>
        </a-tabs>
        <a-button type="link" class="p-0 ml-3" :disabled="isLoading" @click="reloadPage">刷新列表</a-button>
      </div>
      <a-button v-if="hasAuthCreate" type="primary" html-type="submit"
            @click="hanlderNewOrder">新建订单</a-button>
    </div>

    <!-- 全部订单列表 -->
    <base-table
        v-if="!activeKey"
        ref="orderTableRef"
        id="orderTableID"
        rowKey="order_no"
        :columnsData="newColumns"
        :tableData="tableData"
        :rowClassName="setRowClass"
        :total="total"
        :getListFunc="initAllOrder"
        :page.sync="searchForm.page"
        :pageSize.sync="searchForm.page_count"
        @change="handleTableChange">
      <template #order_status="{ record }">
        <a-tag class="mr-0" :color="getUserStatusTagClass(record.order_status)"
            >{{ formatUserOrderStatus(record.order_status, record.order_status_to_suborder_type || record.order_type) }}</a-tag>
      </template>
      <template #inner_status="{ record} ">
        <a-tag class="mr-0" :color="getInnerStatusTagClass(record.order_status)"
            >{{ formatInnerOrderStatus(record.order_status, record.order_status_to_suborder_type || record.order_type) }}</a-tag>
      </template>
      <template #operation="{record}">
        <a-button type="link" @click="handlerDetail(record)">详情</a-button>
      </template>
    </base-table>

    <!-- 其他订单列表 -->
    <base-table
        v-else
        ref="orderTableRef"
        id="orderTableID"
        rowKey="order_no"
        :columnsData.sync="newColumns"
        :tableData="tableData"
        :rowClassName="setRowClass"
        :total="total"
        :getListFunc="initOtherOrder"
        :page.sync="searchFormOther.page"
        :pageSize.sync="searchFormOther.page_count"
        @change="handleTableChange">
      <template #toolbar v-if="activeKey==1 || activeKey==4 || activeKey==6">
        <div v-if="activeKey==1" class="ml-2">
          <a-button v-for="subItem in subTypeList1" :key="subItem.type_id" 
              type="link" class="p-0 mr-6" :class="activeSubKey1==subItem.type_id?'':'grey' "
              @click="handlerSubTypeChange1(subItem.type_id)"
          >{{subItem.type_name}}</a-button>
        </div>
        <div v-if="activeKey==4" class="ml-2">
          <a-button v-for="subItem in subTypeList4" :key="subItem.type_id" 
              type="link" class="p-0 mr-6" :class="activeSubKey4==subItem.type_id?'':'grey' "
              @click="handlerSubTypeChange4(subItem.type_id)"
          >{{subItem.type_name}}</a-button>
        </div>
        <div v-if="activeKey==6" class="ml-2">
          <a-button v-for="subItem in subTypeList6" :key="subItem.type_id" 
              type="link" class="p-0 mr-6" :class="activeSubKey6==subItem.type_id?'':'grey' "
              @click="handlerSubTypeChange6(subItem.type_id)"
          >{{subItem.type_name}}</a-button>
        </div>
      </template>
      <template #order_status="{ record }">
        <a-tag class="mr-0" :color="getUserStatusTagClass(record.order_status)"
          >{{ formatUserOrderStatus(record.order_status, record.order_status_to_suborder_type || record.order_type) }}</a-tag>
      </template>
      <template #inner_status="{record}">
        <a-tag class="mr-0" :color="getInnerStatusTagClass(record.order_status)"
          >{{ formatInnerOrderStatus(record.order_status, record.order_status_to_suborder_type || record.order_type) }}</a-tag>
      </template>
      <template #operation="{record}">
        <a-button type="link" @click="handlerDetail(record)">{{ activeKey==1 ? '审核' : '详情'}}</a-button>
      </template>
    </base-table>
  </div>
</template>

<script>
import moment from "moment"
import { orderSourceList } from "@/utils/type"
import pageData from "./columns"
import {
  getAllList,
  getOtherList,
  getOutletsList,
  getStaffList,
} from "@/api/customer.js"
import { getGoodsList } from "@/api/goods";
import { 
  formatUserOrderStatus,
  formatInnerOrderStatus,
  payTypeList, 
  orderTypeList,
  subOnAccountPayTypeList
} from "@/utils/type"
import { 
  checkAuth,
  getMarketCityList,
  isCityManage,
} from '@/utils/authData.js'

export default {
  components: {
  },
  data () {
    return {
      ...pageData,
      subOnAccountPayTypeList,

      expand: false,

      total: 0,
      tableData: [],

      isCityManage: isCityManage(),
      manageCityList: [],
      outletsList: [],
      staffList: [],

      suggestGoodsList: [],
      timerSuggestGoods: null,

      searchOutletsCityAdcode: undefined,
      searchConsignee: '',
      searchForm: {
        outlets_city_adcode: "",
        order_no: "",
        order_source: 0,
        pay_subtype: "",
        order_status: 0,
        is_update: [],
        orderer_phone: "",
        order_add_time_arr: [],
        order_add_time_start: "",
        order_add_time_end: "",
        order_cancel_time_arr: [],
        order_cancel_time_start: "",
        order_cancel_time_end: "",
        order_remarks: "",
        consignee_recipient_name: "",
        consignee_recipient_phone: "",
        consignee_recipient_time_arr: [],
        consignee_recipient_time_start: "",
        consignee_recipient_time_end: "",
        consignee_address: "",
        actual_sale_ascription_outlets_id: undefined,
        order_type: 0,
        goods_id: undefined,
        goods_name: "",
        goods_type: 0,
        order_amount_start: "",
        order_amount_end: "",
        pay_flow_no: "",
        pay_mode: 0,
        order_finish_time_arr: [],
        order_finish_time_start: "",
        order_finish_time_end: "",
        create_staff_id: undefined,
        dispatch_staff_id: undefined,
        acceptance_time_arr: [],
        acceptance_time_start: "",
        acceptance_time_end: "",
        page: 1,
        page_count: 20,
      },

      searchFormOther: {
        order_status: '',
        order_substatus: '',
        sort_mode: '',
        page: 1,
        page_count: 20,
      },

      orderTypeOptions: [{
          id: 0, 
          name: "全部" 
      }].concat(orderTypeList),
      orderModalOptions: [{
          id: 0, 
          name: "全部" 
      }].concat(payTypeList),
      orderSourceOptions: [{
          id: 0, 
          name: "全部" 
      }].concat(orderSourceList),

      // 一级分类
      activeKey: "",
      typeList: [
        { type_id: 1, type_name: "待审核" },
        { type_id: 2, type_name: "蛋糕已审核" },
        { type_id: 3, type_name: "配送中" },
        // { type_id: 4, type_name: "配送预警订单" },
        { type_id: 5, type_name: "已取消" },
        { type_id: 6, type_name: "售后单" },
        { type_id: 7, type_name: "已交付" },
        { type_id: "", type_name: "全部订单" }
      ],

      // 二级分类：待审核
      activeSubKey1: "",
      subTypeList1: [
        { type_id: 1, type_name: "自提审核" },
        { type_id: 2, type_name: "配送审核" },
        { type_id: 3, type_name: "修改审核" },
        { type_id: "", type_name: "全部待审核" }
      ],

      // 二级分类：配送预警
      activeSubKey4: "",
      subTypeList4: [
        { type_id: 1, type_name: "未接单提醒" },
        { type_id: 2, type_name: "配送超时" },
        { type_id: 3, type_name: "紧急取消" },
        { type_id: "", type_name: "全部预警订单" }
      ],

      // 二级分类：售后单
      activeSubKey6: "",
      subTypeList6: [
        { type_id: 1, type_name: "自提蛋糕订单" },
        { type_id: 2, type_name: "配送订单" },
        { type_id: 3, type_name: "快递订单" },
        { type_id: "", type_name: "全部" }
      ],

      newColumns: [],

      hasAuthCreate: checkAuth('all_order:create'),
      hasAuthAudit: checkAuth('all_order:audit'),

      loginUid: "",

      isLoading: false,
    }
  },
  watch: {
  },
  async mounted () {
    this.searchForm.order_add_time_arr = [
      this.formatDate(new Date((new Date().getTime() - 1*24*3600*1000))),
      this.formatDate(new Date()),
    ]
    
    const userInfo = this.$store.getters.userInfo
    this.loginUid = userInfo && userInfo.uid
    
    this.manageCityList = await getMarketCityList()
    // 如果只管理一个城市：默认选中
    if(this.isCityManage){
      if(this.manageCityList.length == 1){
        this.searchOutletsCityAdcode = Number(this.manageCityList[0].adcode)
      }
    }

    if(this.isCityManage){
      if(this.searchOutletsCityAdcode){
        this.handlerTypeChange()
      }
    }else{
      this.handlerTypeChange()
    }
  },
  methods: {
    moment,
    formatUserOrderStatus,
    formatInnerOrderStatus,

    // 全部订单
    async initAllOrder(otherParams) {
      if(this.isLoading) return

      if(this.isCityManage){
        if(!this.searchOutletsCityAdcode){
          this.$message.info("请选择订单城市")
          return
        }
      }
      this.searchForm.outlets_city_adcode = this.searchOutletsCityAdcode || ""
      
      let sort_mode = this.searchForm.sort_mode
      if(!sort_mode){
        this.searchForm.sort_mode = 12
      }

      otherParams = otherParams || {};
      const params = Object.assign(otherParams, JSON.parse(JSON.stringify(this.searchForm)))

      if (params.order_add_time_arr.length > 0) {
        params.order_add_time_start = params.order_add_time_arr[0]
        params.order_add_time_end = params.order_add_time_arr[1]
      }
      if (params.order_cancel_time_arr.length > 0) {
        params.order_cancel_time_start = params.order_cancel_time_arr[0]
        params.order_cancel_time_end = params.order_cancel_time_arr[1]
      }
      if (params.consignee_recipient_time_arr.length > 0) {
        params.consignee_recipient_time_start =
          params.consignee_recipient_time_arr[0]
        params.consignee_recipient_time_end =
          params.consignee_recipient_time_arr[1]
      }
      if (params.acceptance_time_arr.length > 0) {
        params.acceptance_time_start =
          params.acceptance_time_arr[0] + " 00:00:00"
        params.acceptance_time_end = params.acceptance_time_arr[1] + " 23:59:59"
      }
      if (params.order_finish_time_arr.length > 0) {
        params.order_finish_time_start =
          params.order_finish_time_arr[0] + " 00:00:00"
        params.order_finish_time_end =
          params.order_finish_time_arr[1] + " 23:59:59"
      }

      params.is_update = params.is_update.join(",")
      this.isLoading = true
      const { data, code } = await getAllList(params)
      this.isLoading = false
      if (code === 0) {
        this.tableData = data.list
        this.total = data.total_count
        this.$refs.orderTableRef.tableHeight = 
        this.$refs.orderTableRef.getTableScroll()
      }
    },
    // 其他条件的订单列表
    async initOtherOrder(otherParams) {
      if(this.isLoading) return
      if(this.isCityManage){
        if(!this.searchOutletsCityAdcode){
          this.$message.info("请选择订单城市")
          return
        }
      }
      this.searchFormOther.outlets_city_adcode = this.searchOutletsCityAdcode || ""
      
      let sort_mode = this.searchFormOther.sort_mode
      if(!sort_mode){
        let order_status = this.searchFormOther.order_status
        if(order_status==1){ // 待审核
          this.searchFormOther.sort_mode = 21
        }
        if(order_status==2){ // 蛋糕已审核
          this.searchFormOther.sort_mode = 22
        }
        if(order_status==3){ // 配送中
          this.searchFormOther.sort_mode = 21
        }
        if(order_status==5){ // 已取消
          this.searchFormOther.sort_mode = 12
        }
        if(order_status==6){ // 售后单
          this.searchFormOther.sort_mode = 12
        }
        if(order_status==7){ // 已交付
          this.searchFormOther.sort_mode = 32
        }
      }
      this.clearSortIconStatus()
      otherParams = otherParams || {};
      const params = Object.assign(otherParams, JSON.parse(JSON.stringify(this.searchFormOther)))
      
      this.isLoading = true
      const { data, code } = await getOtherList(params)
      this.isLoading = false

      if (code === 0) {
        this.tableData = data.list
        this.total = data.total_count
        this.$refs.orderTableRef.tableHeight = 
        this.$refs.orderTableRef.getTableScroll()
      }
    },

    onFormChange(){
      this.handlerTypeChange(this.activeKey)
    },

    async initOutletsList (value) {
      const params = {
        outlets_name: value,
      }
      if (value) {
        const { data, code } = await getOutletsList(params)
        if (code === 0) this.outletsList = data.list
      }
    },
    
    async getStaffList (value) {
      if (value) {
        const { data, code } = await getStaffList({
          staff_name: value,
        })
        if (code === 0) this.staffList = data.list
      }
    },

    hanlderNewOrder () {
      this.$router.push(`/order-add`)
    },
    handlerSearch () {
      if(this.isLoading) return

      this.searchConsignee = this.searchConsignee || ""
      if(/^1\d{10}$/.test(this.searchConsignee)){
        this.searchForm.consignee_recipient_name = ""
        this.searchForm.consignee_recipient_phone = this.searchConsignee
      }else{
        this.searchForm.consignee_recipient_name = this.searchConsignee
        this.searchForm.consignee_recipient_phone = ""
      }

      this.searchForm.page = 1
      // this.initAllOrder()
      this.handlerTypeChange()
    },
    resetForm () {
      this.searchConsignee = ""
      this.searchForm.page = 1
      this.searchForm.page_count = 10
      this.$refs.searchRef.resetFields()
      // this.initAllOrder()
      // this.handlerTypeChange(type_id)
      this.handlerTypeChange()
    },
    reloadPage() {
      if(this.isLoading) return
      this.resetForm(this.activeKey)
    },
    //详情
    handlerDetail (row) {
      const newPage = this.$router.resolve({
        name: "order-detail",
        query: { order_no: row.order_no },
      });
      window.open(newPage.href, "_blank");
    },

    handlerExpand () {
      this.expand = !this.expand
    },

    clearSortIconStatus() {
      let icon1 = document.querySelectorAll('.ant-table-column-sorter-up')
      for(let i=0;i<icon1.length;i++){
        icon1[i].className = 'anticon anticon-caret-up ant-table-column-sorter-up off'
      }
      let icon2 = document.querySelectorAll('.ant-table-column-sorter-down')
      for(let i=0;i<icon2.length;i++){
        icon2[i].className = 'anticon anticon-caret-down ant-table-column-sorter-down off'
      }
    },

    handlerTypeChange (type_id) {
      this.activeKey = type_id || ""
      this.searchForm.sort_mode = ""
      this.searchFormOther.sort_mode = ""
      this.tableData = []

      this.clearSortIconStatus()

      // 点击全部
      if (!type_id) {
        this.searchForm.page = 1
        this.newColumns = this.list_columns.filter(
          (item) =>!["courier_name","courier_phone","warning_type","overtime","is_handle"].includes(item.dataIndex)
        )
        this.initAllOrder()
        return
      }

      // 点击其他
      this.searchFormOther.page = 1
      this.searchFormOther.order_status = type_id
      this.searchFormOther.order_substatus = ''
      this.$refs.searchRef.resetFields()

      // 过滤列表显示或者不显示某一列
      if (type_id == 1) {
        this.handlerSubTypeChange1()
      } else if (type_id == 2) {
        this.newColumns = this.list_columns.filter(
          (item) =>!["order_source","order_add_time","order_finish_time","refund_status","courier_name","courier_phone","warning_type","overtime","is_handle"].includes(item.dataIndex)
        )
        this.initOtherOrder()
      } else if (type_id == 3) {
        this.newColumns = this.list_columns.filter(
          (item) =>!["order_source","order_add_time","order_finish_time","order_type","order_status","refund_status","warning_type","overtime","is_handle"].includes(item.dataIndex)
        )
        this.initOtherOrder()
      } else if (type_id == 4) {
        this.handlerSubTypeChange4()
      } else if (type_id == 5) {
        this.newColumns = this.list_columns.filter(
          (item) =>!["order_finish_time","courier_name","courier_phone","order_status","inner_status","refund_status","warning_type","overtime","is_handle"].includes(item.dataIndex)
        )
        this.initOtherOrder()
      } else if (type_id == 6) {
        this.handlerSubTypeChange6()
      } else if (type_id == 7){
        this.newColumns = this.list_columns.filter(
          (item) =>!["courier_name","courier_phone","warning_type","overtime","is_handle"].includes(item.dataIndex)
        )
        this.initOtherOrder()
      }
    },
    handlerSubTypeChange1(subId){
      subId = subId || ""
      this.activeSubKey1 = subId
      
      this.newColumns = this.list_columns.filter(
        (item) =>!["order_finish_time","courier_name","courier_phone","refund_status","warning_type","overtime","is_handle"].includes(item.dataIndex)
      )
      this.searchFormOther.page = 1
      this.searchFormOther.order_status = 1
      this.searchFormOther.order_substatus = subId
      
      this.initOtherOrder()
    },
    handlerSubTypeChange4(subId){
      subId = subId || ""
      this.activeSubKey4 = subId

      this.newColumns = this.list_columns.filter(
        (item) =>!["order_source","order_add_time","order_finish_time","order_type","refund_status","operation"].includes(item.dataIndex)
      )
      this.searchFormOther.page = 1
      this.searchFormOther.order_status = 4
      this.searchFormOther.order_substatus = subId

      this.initOtherOrder()
    },
    handlerSubTypeChange6(subId){
      subId = subId || ""
      this.activeSubKey6 = subId

      this.newColumns = this.list_columns.filter(
        (item) =>!["courier_name","courier_phone","warning_type","overtime","is_handle","inner_status"].includes(item.dataIndex)
      )
      this.searchFormOther.page = 1
      this.searchFormOther.order_status = 6
      this.searchFormOther.order_substatus = subId

      this.initOtherOrder()
    },

    handleTableChange(pagination, filters, sorter) {
      const arr = {
        "order_add_time": {
          ascend: 11, 
          descend: 12
        },
        "consignee_recipient_time_start": {
          ascend: 21, 
          descend: 22
        },
        "order_finish_time": {
          ascend: 31, 
          descend: 32
        },
      }
      if(arr[sorter.columnKey] && sorter.order){
        this.searchForm.sort_mode = arr[sorter.columnKey][sorter.order]
        this.searchFormOther.sort_mode = arr[sorter.columnKey][sorter.order]
      }else{
        this.searchForm.sort_mode = ""
        this.searchFormOther.sort_mode = ""
      }

      if(!this.activeKey){
        this.initAllOrder()
      }else{
        this.initOtherOrder()
      }
    },


    async handlerSuggestGoods (keyword) {
      if(this.timerSuggestGoods){
        clearTimeout(this.timerSuggestGoods)
        this.timerSuggestGoods = null
      }
      this.timerSuggestGoods = setTimeout(()=>{
        this.getSuggestGoods(keyword)
      }, 100)
    },
    async getSuggestGoods(keyword){
      keyword = (keyword || "").replace(/^\s*/g,"").replace(/\s*$/g,"")
      if(!keyword){
        this.suggestGoodsList = []
        return
      }
      const { data, code } = await getGoodsList({
        goods_name: keyword,
        status: 1,
        page_count: 10,
      })
      if (code === 0) {
        this.suggestGoodsList = data.list
      }
    },

    onChangeOrderSource(){
      this.searchForm.pay_subtype = ""
    },

    formatDate(date){
      let year = date.getFullYear()
      let month = date.getMonth()
      let day = date.getDate()
      month = month+1
      if(month < 10){
        month = "0"+month
      }
      if(day < 10){
        day = "0"+day
      }
      return year+"-"+month+"-"+day
    },

    getUserStatusTagClass(status){
      // if(status < 0) return "red"
      if(status < 0) return "grey"
      // if(status < 3) return ""
      if(status < 30) return "blue"
      if(status == 30) return "green"
      if(status >= 40) return "red"
      // if(status >= 40) return "grey"
      return ""
    },
    getInnerStatusTagClass(status){
      // if(status < 0) return "red"
      if(status < 0) return "grey"
      if(status < 3) return ""
      if(status < 30) return "blue"
      if(status >= 30) return "green"
      // if(status >= 40) return "red"
      // if(status >= 40) return "grey"
      return ""
    },

    setRowClass (record) {
      if(record.dispatch_staff_id){
        return record.dispatch_staff_id == this.loginUid ? "row-mine":""
      }
      return ""
    },

  },
}
</script>

<style lang="less">
.search-bottom-class {
  margin-bottom: 20px;
  margin-left: 50px;
}

.drawer-class {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.cancelOrder-class {
  margin-right: 50px;
}
.grey{
  color: #999;
}
</style>
